body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Lato', sans-serif; 
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.item {
  width: 56%;
  overflow: visible;
  stroke: #607d8b;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}